<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/csm_Ersa-VERSAFLOW455-01-900x676_82edd70b55.png"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
              <br><br><br>
              <div class="row">
                <div class="col-2"></div>
                <div class="mt-1 col-6">
                  <h1 class="font-size-48 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>
               The non-plus-ultra in selective soldering!
                  </h2>
                </div>
                </div>
              </div>
            
            </div>
            <!-- end row -->
            <br /><br /><br />
            <div class="row">
            
              <div class="col-xl-6">
                <br /><br />
                <div class="mt-4">
                  <h1>Tech-Highlights VERSAFLOW 4/55</h1>
                  <br />
                  <ul class="container">
                    <li>
                      <h3>
                        Intelligent high-end selective soldering machine for inline production concepts
                      </h3>
                    </li>
                    <br />
                    <li>
                      <h3>Full modularity - suitable for every customer requirement</h3>
                    </li>
                    <br />
                    <li>
                      <h3>Tripling of throughput with highest flexibility</h3>
                    </li>
                    <br />
                    <li>
                      <h3>Intuitive operating concept</h3>
                    </li>
                    <br />
                    <li>
                      <h3>Highest process reliability through proven control systemse</h3>
                    </li>
                    <br />
                  </ul>
                </div>
              </div>
                <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/21csm_Ersa-Selective-SMARTFLOW-006-900x600_ae6f26d723.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br />

      <div class="row">
                  <div class="col-3"></div>
                  <div class="col-6">
                    <h1><strong>Technical data</strong></h1>
                  </div>
                  <div class="col-3"></div>
                </div>
                <br />
                <b-tabs pills card vertical  style="font-size: 18px">
                  <b-tab active title="Dimensions">
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h4>Length: from 2,600 mm</h4>
                      </div>
                      <div class="col">
                        <h4>Height: approx. 1,600 mm</h4>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>Width: approx. 1,800 mm</h4></div>
                      <div class="col"></div>
                    </div>
                  
                  </b-tab>

                  
                  <b-tab title="Conveyor support">
                  
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>Roller conveyor</h4></div>
                      <div class="col"><h4>Max. PCB top side clearance: 120 mm</h4></div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>PCB width: 63,5-508 mm</h4></div>
                      <div class="col"><h4>Max. PCB bottom side clearance: 60 mm</h4></div>
                    </div>
                     <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>PCB length: 127-508 mm</h4></div>
                      <div class="col"><h4>Max. PCB weight: 5 kg (opt. 15 kg)</h4></div>
                    </div>
                   
                    
                  </b-tab>
                   <b-tab title="Flux / Preheat / Solder Module">
                  
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4> <strong>Fluxer:</strong>  Drop-Jet in different sizes</h4></div>
                      <div class="col"><h4><strong> Solder module:</strong></h4></div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4><strong> Preheat module:</strong> IR heating, convection or combination of IR and convection</h4></div>
                      <div class="col"><h4>Electromagnetic solder pot</h4></div>
                    </div>
                     <div class="row" style="margin-left: 10%">
                      <div class="col"><h4></h4></div>
                      <div class="col"><h4>Mini-wave 13 kg up to 6 pots</h4></div>
                    </div>
                   
                    
                  </b-tab>
                 
                </b-tabs>


                <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>


            <div class="row">
              <div class="col-xl-12">
                <div class="">
                  <div class="">
                    <div class="row">
                      <div class="col-xl-6">
                        <!-- 16:9 aspect ratio -->
                        <div class="ratio ratio-21x9">
                          <iframe
                            style="width: 500px; height: 300px"
                            title="YouToube Video"
                            src="https://www.youtube.com/embed/fnq9hnr2p90" 
                            allowfullscreen=""
                          ></iframe>
                        </div>
                      </div>

                     <div class="col-xl-6">
                        <!-- 16:9 aspect ratio -->
                        <div class="ratio ratio-21x9">
                          <iframe
                            style="width: 500px; height: 300px"
                            title="YouToube Video" 
                            src="https://www.youtube.com/embed/XCQSOhMcKrU" 
                            allowfullscreen=""
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
          

            <br /><br />
          
           
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>